// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-editorial-board-js": () => import("./../../../src/pages/editorial-board.js" /* webpackChunkName: "component---src-pages-editorial-board-js" */),
  "component---src-pages-ethical-guidelines-js": () => import("./../../../src/pages/ethical-guidelines.js" /* webpackChunkName: "component---src-pages-ethical-guidelines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructions-for-authors-js": () => import("./../../../src/pages/instructions-for-authors.js" /* webpackChunkName: "component---src-pages-instructions-for-authors-js" */),
  "component---src-pages-instructions-for-reviewers-js": () => import("./../../../src/pages/instructions-for-reviewers.js" /* webpackChunkName: "component---src-pages-instructions-for-reviewers-js" */),
  "component---src-pages-journal-management-js": () => import("./../../../src/pages/journal-management.js" /* webpackChunkName: "component---src-pages-journal-management-js" */),
  "component---src-pages-journal-submission-js": () => import("./../../../src/pages/journal-submission.js" /* webpackChunkName: "component---src-pages-journal-submission-js" */),
  "component---src-pages-login-pages-forget-password-page-js": () => import("./../../../src/pages/loginPages/forgetPasswordPage.js" /* webpackChunkName: "component---src-pages-login-pages-forget-password-page-js" */),
  "component---src-pages-login-pages-home-page-js": () => import("./../../../src/pages/loginPages/homePage.js" /* webpackChunkName: "component---src-pages-login-pages-home-page-js" */),
  "component---src-pages-login-pages-landing-page-js": () => import("./../../../src/pages/loginPages/landingPage.js" /* webpackChunkName: "component---src-pages-login-pages-landing-page-js" */),
  "component---src-pages-login-pages-login-page-js": () => import("./../../../src/pages/loginPages/loginPage.js" /* webpackChunkName: "component---src-pages-login-pages-login-page-js" */),
  "component---src-pages-login-pages-register-page-js": () => import("./../../../src/pages/loginPages/registerPage.js" /* webpackChunkName: "component---src-pages-login-pages-register-page-js" */)
}

